<script setup>
const seoContentObject = {

  title: 'Leasing samochodu - co to?',
  fields: {
    gray: [
      {
        title: 'Leasing samochodu - co to właściwie jest?',
        content: 'Leasing to nic innego, jak umowa cywilnoprawna pomiędzy leasingodawcą, czyli firmą finansującą i udostępniającą pojazd a leasingobiorcą, czyli jego użytkownikiem - przedsiębiorcą lub, coraz częściej konsumentem, który nie prowadzi działalności gospodarczej. W ramach umowy leasingowej konkretny pojazd jest udostępniany leasingobiorcy, który w zamian za możliwość jego użytkowania płaci miesięczne raty. Jest to zatem wygodna formuła, która pozwala od razu korzystać z nowego lub używanego samochodu bez ponoszenia jednorazowo wysokiego kosztu.'
      },
      {
        title: 'Czy z leasingu VEHIS mogą skorzystać nowe firmy?',
        content: 'Tak! Oferta VEHIS jest dostępna również dla nowych firm, czyli tzw. startupów. Finansujemy firmy od pierwszego dnia działalności gospodarczej.'
      },
    ],
    field: [
      {
        title: 'Leasing samochodu - jak działa?',
        content: '<p>Zastanawiasz się, jak dokładnie przebiega leasing samochodu? Na czym polega? Zasady korzystania z pojazdu, w ramach tej formy finansowania, reguluje umowa leasingu samochodu.</p>\n' +
          '<p>Określa ona wysokość rat i harmonogram ich spłaty jak również zasady użytkowania samochodu w trakcie trwania umowy. Raty leasingowe są płacone w oparciu o comiesięczną fakturę, która dla przedsiębiorców stanowi koszt uzyskania przychodu. Przedsiębiorcy mogą więc dzięki leasingowaniu samochodów pomniejszyć wysokość zobowiązań podatkowych.</p>\n' +
          '<p>W okresie jej obowiązywania właścicielem pojazdu pozostaje leasingodawca. W związku z tym może on określić zasady eksploatacji samochodu (np. dotyczące przeróbek, ubezpieczenia czy zasad serwisowania). Po zakończeniu umowy leasingowej użytkujący samochód może wykupić go na własność za ustaloną z góry kwotę lub po prostu zwrócić go firmie leasingowej.&nbsp;</p>\n'
      },
      {
        title: 'Leasing Vehis - czym się wyróżnia?',
        content: '<p>Oferta Vehis kompleksowo odpowiada na potrzeby klientów związane z nabyciem, finansowaniem, ubezpieczeniem i eksploatowaniem samochodu. Znajdziesz w niej nie tylko największy wybór samochodów z wysokimi rabatami, ale także leasing operacyjny lub konsumencki z niską ratą i promocyjną ofertą ubezpieczeń</p>\n' +
          '<p>Leasing samochodowy Vehis to przede wszystkim szybka i sprawna obsługa (decyzję otrzymasz w 15 minut!), uproszczona procedura, w której nie wymagamy dokumentów finansowych, czy minimalna opłata wstępna. To oznacza, że szybko dopełnimy formalności i swój nowy samochód odbierzesz nawet w 24h!</p>\n' +
          '<p>W trakcie trwania umowy zaoferujemy Ci pełne wsparcie serwisowe i pomoc w razie wypadku. Brak limitu kilometrów, a co za tym idzie opłat za nadprzebieg oraz brak opłat za ponadnormatywne zużycie powodują, że niezależnie od sposobu zakończenia umowy nie poniesiesz dodatkowych kosztów z tego tytułu. Na zakończenie umowy leasingu VEHIS masz możliwość wykupienia samochodu po ustalonej z góry cenie, rozłożenia opłaty końcowej na raty oraz wymiany lub zwrotu samochodu.</p>\n' +
          '<p>Z tych możliwości możesz też skorzystać w trakcie trwania umowy leasingowej! Wszystko po to, by w pełni dostosować się do oczekiwań naszych klientów i konstruować dla nich najkorzystniejsze warunki.</p>\n'
      },
      {
        title: 'Kredyt samochodowy vs leasing VEHIS',
        content: '<p>Leasing samochodu VEHIS to znacznie większa łatwość i elastyczność niż w przypadku kredytu samochodowego. Przede wszystkim procedura uruchomienia finansowania w leasingu VEHIS jest znacznie szybsza i prostsza niż w klasycznym kredycie samochodowym. Wymagamy mniej dokumentów i szybciej przyznamy Ci finansowanie. Dzięki odpowiednio dobranej wartości końcowej Twoja miesięczna rata może być znacznie niższa niż w kredycie. Możesz korzystać z pojazdu przez cały okres leasingu VEHIS i dopiero na jego koniec stwierdzić czy chcesz dokonać zwrotu pojazdu, czy pragniesz go wykupić lub przedłużyć obecną umowę.</p>\n'
      },
      {
        title: 'Wynajem długoterminowy, a leasing samochodowy VEHIS',
        content: '<p>Najważniejszą przewagą tej formy finansowania nad wynajmem długoterminowym, jest elastyczność zakończenia umowy. Najem długoterminowy również zakłada użyczenie pojazdu na czas trwania umowy, ale na jej zakończenie samochód należy zwrócić firmie wynajmującej. Jeżeli najemca jest zainteresowany wykupieniem samochodu to najczęściej kwota wykupu jest określana według jego wartości rynkowej na moment zakończenia umowy najmu. Jest to więc znacznie mniej korzystna forma w kontekście wykupu.</p>\n' +
          '<p>Jeżeli Twoją intencją jest tylko możliwość eksploatowania samochodu, bez przejmowania go na własność na koniec umowy, to umowa najmu wydaje się być atrakcyjną formą, ale trzeba pamiętać o dwóch istotnych aspektach: limit przebiegu i opłaty za ponadnormatywne zużycie.</p>\n' +
          '<p>Limit przebiegu oznacza, że jeszcze przed zawarciem umowy najmu musisz określić ile kilometrów rocznie będziesz pokonywał. Szacunek powinien być dokładny, bo jest to parametr istotnie wpływający na wysokość miesięcznej raty. Przeszacowany przebieg będzie oznaczał zbyt wysokie koszty, a podanie zbyt niskiego przebiegu będzie oznaczało konieczność dopłaty za każdy kilometr ponad limit. Sam zwrot pojazdu wiąże się też z oględzinami rzeczoznawcy, który oceni, czy samochód, który zwracasz nie jest ponadnormatywnie wyeksploatowany. W praktyce oznacza to, że zostaniesz obciążony dopłatą za usunięcie wszystkich uszkodzeń uznanych za ponadnormatywne.</p>\n' +
          '<p>Biorąc pod uwagę powyższe warto rozważyć leasing samochodu osobowego lub dostawczego w VEHIS, gdzie nie ma limitu kilometrów, nie ma opłat za ponadnormatywne zużycie, a o sposobie zakończenia umowy zadecydujesz w najbardziej odpowiednim dla Ciebie momencie.</p>\n'
      },
      {
        title: 'Leasing samochodu osobowego - oferta Vehis',
        content: '<p>Nasza oferta leasingowa obejmuje samochody osobowe nowe i używane do 5 lat. W ofercie VEHIS mamy samochody najpopularniejszych marek w Polsce i szeroki wybór różnej klasy modeli. Niezależnie czy szukasz reprezentacyjnego samochodu klasy premium, jak BMW, Audi, Mercedes, Volvo, Lexus czy też potrzebujesz samochodu osobowego popularnych marek, jak Ford, Fiat, Volkswagen, Toyota, Renault czy Dacia, to najlepszą ofertę znajdziesz w VEHIS!</p>\n' +
          '<p>Warto dodać, że nasze samochody pochodzą z polskiej sieci dealerskiej i są objęte pełną gwarancją producenta. Jeżeli nie masz sprecyzowanych potrzeb to nasi doradcy chętnie pomogą Ci wybrać najkorzystniejsze oferty samochodów osobowych w leasingu operacyjnym lub konsumenckim.</p>\n'
      },
      {
        title: 'Leasing samochodu dostawczego od Vehis',
        content: '<p>Jesteś zainteresowany pojazdem, który wykorzystywany będzie do prowadzonej działalności gospodarczej? Dobrym wyborem może być leasing samochodu dostawczego. Zapewniamy Ci szeroki wybór modeli - m.in. takich marek jak: Citroen, Ford, Iveco, Opel.</p>\n' +
          '<p>Warto zwrócić uwagę na samochody, które są gotowe do odbioru, aby szybko mogły zacząć pracować dla Twojej firmy. Sama procedura nabycia samochodu dostawczego w leasingu operacyjnym VEHIS również jest szybka i sprawna - decyzja nawet w 15 minut i odbiór pojazdu nawet w 24h!</p>\n' +
          '<p>Ponadto niski wkład własny, elastyczny czas trwania umowy i elastyczne możliwości jej zakończenia, to cechy, które są doceniane przez naszych klientów. Jeżeli prowadzisz firmę i intensywnie wykorzystujesz swoje samochody dostawcze, to szczególnie docenisz to, że w leasingu VEHIS nie ma limitu kilometrów.&nbsp;</p>\n'
      },
      {
        title: 'Wybierz leasing konsumencki samochodu',
        content: '<p>Jeszcze kilka lat temu z leasingu korzystać mogły tylko osoby prowadzące działalność gospodarczą lub spółki prawa handlowego. Dziś leasing samochodu dla osoby prywatnej to coraz częstszy wybór klientów. Nic dziwnego - firmy leasingowe prześcigają się w coraz atrakcyjniejszych warunkach leasingu konsumenckiego.</p>\n' +
          '<p>Nie bez znaczenia jest też większa łatwość uzyskania finansowania w leasingu niż w kredycie, w którym banki szczegółowo badają zdolność kredytową i wymagają przedstawienia szeregu dokumentów. W VEHIS dla samochodów o wartości do 150.000 zł wystarczy nam tylko Twoje oświadczenie o dochodach.</p>\n' +
          '<p>Warto podkreślić, że VEHIS oferuje program wsparcia serwisowego i ubezpieczenie w cenie przez cały okres umowy. Ważne jest też to, że wysokość składki polisy ubezpieczeniowej naliczymy Ci od wartości netto samochodu, co oznacza, że polisa będzie tańsza o wysokość podatku VAT. Nasza opłata wstępna jest naprawdę niska (od 1%), a auto szybko dostępne do Twojej dyspozycji.&nbsp;&nbsp;</p>\n'
      },
      {
        title: 'Co to jest leasing konsumencki?',
        content: 'Leasing konsumencki to alternatywa dla kredytu, z której mogą skorzystać osoby nieprowadzące działalności gospodarczej. Jednym słowem nie musisz mieć firmy - po prostu podpisujesz umowę, płacisz raty leasingowe, a na koniec trwania umowy decydujesz: oddajesz auto, wykupujesz czy przedłużasz zobowiązanie.'
      },
      {
        title: 'Leasing samochodu a wykup - samochód w zasięgu ręki',
        content: '<p>Zdecydowałeś się na leasing? Pamiętaj, że na jego zakończenie możesz wykupić użytkowany samochód, przedłużyć umowę lub wymienić albo oddać pojazd. Już przy podpisaniu umowy, jako leasingobiorca decydujesz, jaka będzie kwota jego wykupu. W odróżnieniu od najmu długoterminowego ta wartość pozostaje bez zmian i nie będziesz obciążony dodatkowymi opłatami z tytułu nadprzebiegu czy ponadnormatywnego zużycia samochodu.</p>'
      },
      {
        title: 'Leasing samochodu powyżej 150 tys. zł w firmie Vehis',
        content: '<p>W ofercie Vehis znajdziesz także luksusowe marki motoryzacyjne, z których korzystać możesz wybierając leasing samochodu powyżej 150 tys zł. Pomożemy Ci w kwestiach formalnych - wyjaśnimy: jak działa leasing na firmę powyżej tej wartości samochodu osobowego oraz jak możesz odpowiednio rozliczyć koszty uzyskania przychodu.&nbsp;</p>\n'
      },
      {
        title: 'Leasing samochodu używanego - atrakcyjna opcja finansowania',
        content: '<p>Samochody używane stanowią istotny segment naszej oferty - cieszą się także dużym zainteresowaniem klientów . Samochody używane w ofercie VEHIS to sprawdzone i pewne pojazdy, do których dysponujemy pełną oceną niezależnego rzeczoznawcy. Dlatego leasing samochodu używanego z VEHIS, to atrakcyjna alternatywa do niepewnych propozycji z rynku wtórnego.</p>\n' +
          '<p>Warunki jakie musisz spełnić, aby wziąć samochód używany w leasingu VEHIS są takie same jak dla samochodów nowych. Tym samym korzystasz ze wszystkich zalet naszego finansowania: szybki proces, niskie raty i elastyczne warunki zakończenia umowy.</p>\n'
      },
      {
        title: 'Czy leasing auta jest korzystniejszy od zakupu samochodu?',
        content: 'Oczywiście! W przypadku leasingu nie musisz jednorazowo wydać dużej kwoty, więc nie musisz rezygnować z innych swoich planów, a jeżeli kupujesz samochód dla swojej firmy nie musisz rezygnować z innych inwestycji i nie pogarszasz swojej płynności finansowej. Dodatkowo możesz uzyskać atrakcyjne oferty - np. rabat na samochód zarezerwowany tylko dla największych klientów czy też ubezpieczenie z możliwością rozłożenia na raty. Ponadto jako przedsiębiorca możesz zaliczyć całą ratę leasingu w koszty i o jej wartość obniżyć wartość swoich zobowiązań podatkowych.'
      },
      {
        title: 'Jakie są najczęściej leasingowane marki samochodów?',
        content: '\'Wśród najczęściej leasingowanych samochodów od kilku lat, niezmiennie, znajdują się: Toyota Corolla, Toyota RAV4, Toyota Yaris i Skoda Octavia. Wysoko w rankingu najchętniej użytkowanych w tej formie finansowania aut znajdują się także: Hyundai I30, Kia Sportage i Hyundai Tucson. Segment premium został zdominowany przez trójkę niemieckich producentów, czyli Audi, BMW i Mercedesa. Modele Serii 3, X3, a4, Q5, Q3, GLC, czy klasa A i C to samochody najczęściej nabywane w ramach umowy leasingu.\''
      },
      {
        title: 'Dla kogo przeznaczony jest leasing samochodu?',
        content: 'Leasing samochodu przeznaczony jest dla każdego, kto chce szybko zacząć korzystać z pojazdu bez konieczności jednorazowego wydawania dużej gotówki. Obecna oferta leasingowa skierowana jest zarówno do firm, niezależnie od formy prowadzenia działalności gospodarczej: od jednoosobowych działalności gospodarczych po spółki kodeksu handlowego, ale również do osób fizycznych w ramach leasingu konsumenckiego.'
      },
      {
        title: 'Jakie warunki muszę spełnić, by wziąć leasing?',
        content: 'Znalazłeś swój wymarzony samochód i dokonałeś wstępnego wyliczenia rat wykorzystując nasz kalkulator leasingu? Aby pójść dalej i nabyć samochód w leasingu VEHIS musisz złożyć wniosek leasingowy. To prosty dokument, w którym musisz podać kilka bazowych informacji (m.in. NIP w przypadku przedsiębiorców lub oświadczenie o miejscu zatrudnienia i dochodzie w przypadku konsumentów) oraz wyrazić zgodę na weryfikację w bazach (może być telefonicznie). Następnie nasi analitycy zweryfikują jaka jest Twoja zdolność kredytowa.\n' +
          '\n' +
          'W uproszczonej procedurze decyzję otrzymasz nawet w 15 minut. Jeżeli będą potrzebne dodatkowe dokumenty, to o wszystkim powiadomi Cię Twój Doradca Handlowy, który będzie też czuwał, żebyśmy jak najsprawniej mogli zawrzeć umowę leasingową i bez zbędnej zwłoki rozpoczęli użytkowanie samochodu.'
      },
    ]
  }
}

const gray = (index) => seoContentObject.fields.gray[index];
const field = (index) => seoContentObject.fields.field[index];
</script>

<template>
  <div class="font-extralight text-sm">
    <div class="h-0 my-8 mx-auto w-full"></div>
    <h2 class="w-full text-center text-lg font-normal mb-10">{{ seoContentObject.title }}</h2>
    <div class="flex flex-col justify-center">
      <SeoContentBlock :data="gray(0)" gray />
    </div>

    <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <SeoContentBlock :data="field(0)" />
      <SeoContentBlock :data="field(1)" />
    </div>
    <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
      <SeoContentBlock :data="field(2)" />
      <div class="px-14 flex flex-col justify-center">
        <img src="@/assets/images/rata.png" alt="Leasing samochodu w Vehis">
      </div>
    </div>
    <SeoContentBlock :data="field(3)" />
    <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <SeoContentBlock :data="field(4)" />
      <SeoContentBlock :data="field(5)" />
    </div>
    <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-6">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 col-span-2 lg:col-span-3">
        <SeoContentBlock :data="field(6)" />
        <div class="lg:col-span-2 space-y-6">
          <div>
            <img src="@/assets/images/polisa.png" alt="Ubezpieczenie samochodu w leasingu" />
          </div>
          <SeoContentBlock :data="field(7)" />
          <SeoContentBlock :data="field(8)" />
        </div>
      </div>

      <div class="space-y-6 col-span-2 lg:col-span-1">
        <SeoContentBlock :data="field(9)" />
        <SeoContentBlock :data="field(10)" />
      </div>
    </div>
    <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
    <div class="grid grid-cols-1 lg:grid-cols-4 gap-6">
      <div class="grid grid-cols-1 md:grid-cols-3 lg:col-span-3 gap-6">
        <SeoContentBlock :data="field(11)" />
        <SeoContentBlock :data="field(12)" />
        <SeoContentBlock :data="field(13)" />
        <SeoContentBlock class="md:col-span-3" :data="gray(1)" gray />
      </div>
      <SeoContentBlock :data="field(14)" />
    </div>
  </div>
</template>

