<script setup>
const {breakpoint} = useBreakpoints();
const isMobileAspectRatio = computed(() => !breakpoint.value || breakpoint.value === 'sm');

const scale = ref(1);

const scaleTransform = computed(() => `scale(${scale.value})`);

const heightScaled = computed(() => {
  const height = scale.value * 240;
  return `${height}px`;
});

</script>

<template>
  <Container no-outer-paddings no-paddings>
    <div class="block html5-benefits-banner-holder">
      <iframe src="https://images.vehistools.pl/html5/vehis_korzysci_1336x670/vehis_korzysci_1336x670.html"
              class="html5-desktop-benefits-banner"></iframe>
      <iframe src="https://images.vehistools.pl/html5/vehis_korzysci_390x670/vehis_korzysci_390x670.html"
              class="html5-mobile-benefits-banner"></iframe>
    </div>
  </Container>
</template>
